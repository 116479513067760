<template>
    <KCourse loader-class="MBcont" :course-id="courseId" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p><b>Сборка.</b> Быстрая сборка оборок.</p>
        </div>

        <VideoView video-id="cc8521fd28ab4e228536d14e575de8a1"/>

        <div class="MBtextcont">
            <p>Простой машинный <b>шов с закрепкой.</b></p>
        </div>

        <VideoView video-id="50cc43e7cfda40968871bc39efe0eefc"/>

        <div class="MBtextcont">
            <p>Машинный <b>двойной шов</b> для обработки среза тонких тканей</p>
        </div>

        <VideoView video-id="99e16b473f704c3d9e4822fd21013f6d"/>

        <div class="MBtextcont">
            <p>Машинный шов <b>зигзаг</b>, для обработки среза.</p>
        </div>

        <VideoView video-id="be5e0d970c714f758518bfa06fda4f87"/>

        <div class="MBtextcont">
            <p>Машинный шов <b>рулик</b>, или шов в подгибку с открытым срезом.</p>
        </div>

        <VideoView video-id="48ec9f9c9d1a452880ca006aef384583"/>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
      props: {
        courseId: {
          type: String,
          default: "4"
        },
        items: {
          type: Array,
          default: [
            {title: "Подготовка", url: "/mycourses/costume/1"},
            {title: "Снимаем выкройку", url: "/mycourses/costume/2"},
            {title: "Моделируем", url: "/mycourses/costume/3"},
            {title: "Ткань. Раскрой", url: "/mycourses/costume/4"},
            {title: "Нижнее белье", url: "/mycourses/costume/5"},
            {title: "Шьем полочку", url: "/mycourses/costume/6"},
            {title: "Дошиваем платье", url: "/mycourses/costume/7"},
            {title: "Красное платье", url: "/mycourses/costume/8"},
            {title: "Делаем обувь", url: "/mycourses/costume/9"},
            {title: "Ручные швы", url: "/mycourses/costume/10"},
            {title: "Машинные швы", url: "/mycourses/costume/11"},
            {title: "Утюжка", url: "/mycourses/costume/12"},
          ]
        }
      }
    }
</script>